.remix-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.new-selector-container {
    display: flex;
    margin-bottom: 15px;
}


@media only screen and (max-width: 768px) {
    .new-selector-container {
        display: block;
    }
}