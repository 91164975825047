.song-card-container {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    border-radius: 3px;
}

.song-card-container:hover {
    background-color: rgb(27, 27, 27);
}

.play-button-container {

    width: 60px;
    height: 100%;
    
    /* align center along y axis */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.song-title-container {

    width: 20%;
    height: 100%;


    /* align center along y axis */
    display: flex;
    flex-direction: column;
    justify-content: center;


    margin-left: 30px;
    margin-right: 5px;
}

.song-source-container {

    min-width: 10%;
    height: 100%;


    /* align center along y axis */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    margin-left: 5px;
    margin-right: 5px;
}

.song-waveform-container {

    width: calc(50% - 125px);
    height: 100%;

    /* align center along y axis */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    margin-left: 15px;
    margin-right: 5px;
}

.duration-label {
    white-space: nowrap;
    margin-left: 10px;
    font-size: 24px;
    color: var(--header-deselect-color);
}

.queue-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
}

.song-actions-container {
    
    width: 20%;
    height: 100%;

    /* align center along y axis */
    /* align to the right along x axis */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    margin-left: 5px;
    padding-right: 5px;
}

@media only screen and (max-width: 768px) {
    .song-waveform-container {
        width: 100%;
    }
    .duration-label {
        display: none;
    }
    
    .song-title-container {
        margin-left: 0px;
    }

    .song-actions-container {
        width: 30%;
    }

    .song-source-container {
    align-items: flex-start;
    justify-content: first baseline;
    min-width: 20%;
    }
}