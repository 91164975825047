.header {
    margin: 20px 0 20px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between; /* This will push the left and right divs to the edges, and the center div will naturally be in the middle */
    align-items: center; /* This will vertically center the items if needed */
    width: 100%; /* Take the full width of the parent */
}

.logo {
    /*Don't allow users to highlight or interact with text*/
    user-select: none;
    pointer-events: none;
}

.logo-1 {
    font-size: 32px;
    color: var(--header-select-color);
}
.logo-2 {
    font-size: 32px;
    color: var(--header-deselect-color);
}

.nav-buttons {
    display: flex;
    align-items: center;
}

.nav-button {
    width: 200px;
    font-size: 32px;
    color: var(--header-deselect-color);
    
    /* Take off all other button styling*/
    border: none;
    background-color: transparent;
    outline: none;
    text-decoration: none;
    cursor: pointer;
    text-align: center;
}

.btn-selected {
    color: var(--header-select-color);
}