.detail-container {
    width: 80%;
    margin-left: 10%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.detail-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

}

.detail-audio {
    width: 100%;
    height: 100px;
    margin-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.detail-title {
    display: flex;
    flex-direction: row;
}

.detail-remix-container {
    width: 100%;
    height: 300px;
}

.detail-actions-container {
    margin-bottom: 50px;
}


/* Launch */

.launch-modal-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.launch-modal-title {
    margin-bottom: 15px;
    font-size: 32px;
    font-weight: 400;
}

.launch-modal-url {
    text-decoration: none;
    color: inherit;
    border-bottom: 1px solid white;
    padding: 10px;
}


@media only screen and (max-width: 768px) {
    .detail-remix-button {
        margin-bottom: 20px;
    }
}