.launch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10vh;
    height: 100%;
    width: 80%;
    margin-left: 10%;
}

.launch-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.launch-title {
    font-size: 2rem;
    font-weight: 600;
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: center;
    text-align: center;
}

.launch-artist {
    font-size: 1rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: center;
    text-align: center;
}

.launch-clip {
    margin-top: 50px;
    width: 100%;
}

.wavesurfer-container {
    display: flex;
    flex-direction: row;
}

.play-button-launch-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.launch-footer {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.logo {
    /*Don't allow users to highlight or interact with text*/
    user-select: none;
    pointer-events: none;
}

.logo-1 {
    font-size: 32px;
    color: var(--header-select-color);
}
.logo-2 {
    font-size: 32px;
    color: var(--header-deselect-color);
}

.learn-more-link {
    text-decoration: none;
    color: var(--header-select-color);
    font-size: 1.5rem;
    font-weight: 400;
    margin: 0;
    padding: 0;
    width: 100%;
    align-items: center;
    text-align: center;
    border: solid 1px var(--header-select-color);
}