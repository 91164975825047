.song-waveform-container {
    width: 100%;
    height: 40px;
}

.file-container {
    width: 60%;
    margin-left: 20%;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

}

.file-dropzone-container {
    width: 100%;
}