body {
  margin: 0;
  background-color: black;
  color: white;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --header-select-color: white;
  --header-deselect-color: #929292;
}

.text-container {
  padding: 5px 10px;
  min-width: 0;
  font-size: 2rem;
  color: #708090;
  white-space: nowrap;
  overflow: visible;
  position: relative;
  border: 1px solid #708090;
  border-radius: 5px;

  span {
    display: inline-block;
  }

  .animate {
    position: relative;
    animation: leftright 3s infinite alternate ease-in-out;
  }
}

.MuiPaper-root {
  display: none;
}

@keyframes leftright {
  0%,
  20% {
    transform: translateX(0%);
    left: 0%;
  }
  80%,
  100% {
    transform: translateX(-100%);
    left: 100%;
  }
}

.fader {
  position: absolute;
  top: 0;
  height: 100%;
  width: 25px;

  &.fader-left {
    left: 0;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }

  &.fader-right {
    right: 0;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1)
    );
  }
}

@media (max-width: 450px) {
  .center {
    width: 100%;
    padding: 20px;
  }
}
