.section-container {
    width: 25%;
    border-bottom: 1px solid #868e96;
}

.section-elements-container {
    margin-top: 20px;
    width: 94%;
    margin-left: 3%;
}


@media only screen and (max-width: 768px) {
    .section-container {
        width: 100%;
    }
    .section-elements-container {
        width: 100%;
        margin-left: 0;
    }
}