/* .song-waveform-container {
    width: 100%;
    height: 40px;
} */

.youtube-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
}

.youtube-link-container {
    width: 60%;
}