.create-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 10%;
}

@media only screen and (max-width: 768px) {
    .create-container {
        padding: 0 2%;
    }
}