.scratch-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
}

.create-section-header {
    width: 100%;

    display: flex;
    flex-direction: row;

    margin-bottom: 30px;
}


.scratch-autocomplete-container {
    margin-bottom: 10px;
}

.search-extra-params-container {
    white-space: nowrap;
    width: 100%;
    justify-content: first baseline;
    margin-top: 5px;
    margin-left: 3%;
}

.scratch-accordion-container {
    display: flex;

}

.mantine-Accordion-label {
    padding: 0.4rem;
}
.mantine-Accordion-chevron {
    margin-left: 0.2rem;
}
.mantine-Accordion-control {
    padding: 0;
}

.bpm-container {
    width: 100px;
}

.key-container {
    width: 100px;
}

.duration-container {
    width: 100px;
}

.scratch-genres-container, .scratch-moods-container {
    width: 100%;
}

.scratch-search-container {
    width: 70%;
    margin-bottom: 50px;
}
.scratch-clear {
    font-size: 12px;
    color: gray;
}

.scratch-chips-container {
    font-size: 14px;
    color: gray
}

.scratch-examples-container, .scratch-generations-container {
    width: 100%;
    margin-bottom: 20px;
}

.scratch-genre-chips-container {
    overflow: scroll;
    white-space: nowrap;
}

.scratch-mood-chips-container {
    overflow: scroll;
    white-space: nowrap;
    padding-bottom: 12px;
}

@media only screen and (max-width: 768px) {
    .scratch-accordion-container {
        display: block;
    }

    .scratch-search-container {
        width: 100%;
    }

    .mantine-Accordion-label {
        font-size: 0.8rem;
    }
}