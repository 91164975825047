.slide-object-container {

    /* align center along y axis */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin: 0px 50px;
    color: white;

    transition: all 0.5s ease;

    /* change cursor to be clickable */
    cursor: pointer;
}

.slide-container {
    width: 100%;
    height: 100%;

    /* align center along y axis */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.slide-image {
    margin-bottom: 5px;
}

.not-chosen {
    filter: grayscale(100%);
    color: #545454;
}